import React, { useState, useEffect } from "react";
import ReactCardFlip from "react-card-flip";
import { useAuth } from "../AuthProvider";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";

// ==============Badges===============
import asesinobadge from "../../imgs/badges/asesino-badge.webp";
import asesinogoldbadge from "../../imgs/badges/asesino-gold-badge.webp";
import brotherbadge from "../../imgs/badges/brother-badge.webp";
import brothergoldbadge from "../../imgs/badges/brother-gold-badge.webp";
import club42badge from "../../imgs/badges/club42-badge.webp";
import club42goldbadge from "../../imgs/badges/club42-gold-badge.webp";
import paciente0badge from "../../imgs/badges/paciente0-badge.webp";
import paciente0goldbadge from "../../imgs/badges/paciente0-gold-badge.webp";
import vuelosecretobadge from "../../imgs/badges/vuelo-secreto-badge.webp";
import vuelosecretogoldbadge from "../../imgs/badges/vuelo-secreto-gold-badge.webp";
import dorsobadge from "../../imgs/badges/dorso-badge.webp";
import dorsogoldbadge from "../../imgs/badges/dorso-gold-badge.webp";

//PopUp que muestra el perfil del usuario para ver sus badges y detalles
//Creado por: Alberto Cebreros

const ProfilePopup = ({ onClose, onDeleteAccount }) => {
  const { currentUser } = useAuth();
  const [badges, setBadges] = useState({});
  const [flippedStates, setFlippedStates] = useState({});

  useEffect(() => {
    if (!currentUser) return;

    const loadBadges = async () => {
      const rooms = [
        "asesino_imitador",
        "big_brother",
        "club_42",
        "paciente_0",
        "vuelo_secreto",
      ];
      const newBadges = {};
      const newFlippedStates = {};

      for (const room of rooms) {
        const bestTime = await getBestTime(room);
        newBadges[room] = bestTime;
        newFlippedStates[room] = false; // Inicialmente no volteadas
      }

      setBadges(newBadges);
      setFlippedStates(newFlippedStates);
    };

    loadBadges();
  }, [currentUser]);

  const toggleFlip = (roomId) => {
    setFlippedStates((prev) => ({
      ...prev,
      [roomId]: !prev[roomId],
    }));
  };

  // Función para obtener el badge adecuado
  const getBadge = (badge, goldBadge, condition) => {
    return condition ? goldBadge : badge;
  };

  const getBestTime = async (roomId) => {
    try {
      const userDocRef = doc(db, roomId, currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const data = userDocSnap.data();
        return data.bestTime || null;
      }
      return null;
    } catch (error) {
      console.error(`Error al obtener bestTime para ${roomId}:`, error);
      return null;
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Asegurarse de que los valores tengan dos dígitos
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  if (!currentUser) {
    return <p>Cargando...</p>;
  }

  const userName = currentUser.firstName || "Nombre no disponible";
  const userLastName = currentUser.lastName || "Apellido no disponible";
  const userEmail = currentUser.email;

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="relative w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow">
        {/* Botón de cierre */}
        <button
          className="absolute top-2 right-4 text-white text-2xl"
          onClick={onClose}
        >
          &times;
        </button>

        <div className="flex flex-col items-center text-white mb-4">
          {/* User Avatar */}
          <div className="w-16 h-16 bg-gray-500 rounded-full flex items-center justify-center text-xl font-bold mb-4">
            {userName.charAt(0)}
            {userLastName.charAt(0)}
          </div>

          {/* User Name */}
          <h2 className="text-2xl font-bold">
            {userName} {userLastName}
          </h2>
          <p className="text-sm text-gray-300">{userEmail}</p>
        </div>

        {/* Badges Section */}
        <div className="flex justify-center space-x-2 my-4">
          {[
            {
              roomId: "asesino_imitador",
              front: badges["asesino_imitador"]
                ? asesinogoldbadge
                : asesinobadge,
              back: badges["asesino_imitador"] ? dorsogoldbadge : dorsobadge,
            },
            {
              roomId: "big_brother",
              front: badges["big_brother"] ? brothergoldbadge : brotherbadge,
              back: badges["big_brother"] ? dorsogoldbadge : dorsobadge,
            },
            {
              roomId: "club_42",
              front: badges["club_42"] ? club42goldbadge : club42badge,
              back: badges["club_42"] ? dorsogoldbadge : dorsobadge,
            },
            {
              roomId: "paciente_0",
              front: badges["paciente_0"] ? paciente0goldbadge : paciente0badge,
              back: badges["paciente_0"] ? dorsogoldbadge : dorsobadge,
            },
            {
              roomId: "vuelo_secreto",
              front: badges["vuelo_secreto"]
                ? vuelosecretogoldbadge
                : vuelosecretobadge,
              back: badges["vuelo_secreto"] ? dorsogoldbadge : dorsobadge,
            },
          ].map((badge, index) => (
            <ReactCardFlip
              key={index}
              isFlipped={flippedStates[badge.roomId]}
              flipDirection="horizontal"
            >
              <img
                src={badge.front}
                alt={`${badge.roomId} badge`}
                className="w-14 h-14 cursor-pointer"
                onClick={() => toggleFlip(badge.roomId)}
              />
              <div
                className="relative w-14 h-14 cursor-pointer"
                onClick={() => toggleFlip(badge.roomId)}
              >
                {/* Imagen del dorso */}
                <img
                  src={badge.back}
                  alt="Dorso badge"
                  className="w-full h-full object-cover"
                />
                {/* Texto del tiempo sobre la imagen */}
                <div className="absolute inset-0 flex items-center justify-center text-black font-bold text-sm">
                  {badges[badge.roomId]
                    ? `${formatTime(badges[badge.roomId])}`
                    : ""}
                </div>
              </div>
            </ReactCardFlip>
          ))}
        </div>

        {/* Profile Details Section */}
        <div className="text-white mt-4">
          <h3 className="text-lg font-semibold mb-2">Profile Details</h3>
          <div className="space-y-2 text-sm">
            <p>
              <strong>Name:</strong> {userName}
            </p>
            <p>
              <strong>Surname:</strong> {userLastName}
            </p>
            <p>
              <strong>Email:</strong> {userEmail}
            </p>
          </div>
        </div>
        {/* Texto para eliminar cuenta */}
        <div className="mt-6 flex justify-center">
          <button
            className="text-red-500 text-sm underline"
            onClick={onDeleteAccount}
          >
            Eliminar cuenta
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfilePopup;
