import React, { useState } from "react";
import Form from "./ui/form";
import SignUp from "./SignUp"; // Importa el componente SignUp

function SignIn({ onClose }) {
  const [showSignUp, setShowSignUp] = useState(false);

  const handleSwitchToSignUp = () => {
    setShowSignUp(true); // Abre el formulario de registro
  };

  const handleSignInSuccess = () => {
    onClose(); // Cierra el pop-up cuando el inicio de sesión es exitoso
  };

  if (showSignUp) {
    return (
      <SignUp
        onClose={() => {
          setShowSignUp(false);
          onClose();
        }}
      />
    ); // Cierra el formulario de inicio de sesión al abrir el de registro
  }

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black/50 z-50">
      <div className="relative w-full max-w-sm mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow">
        <button
          className="absolute top-2 right-4 text-white text-2xl"
          onClick={onClose}
        >
          &times;
        </button>

        <h2 className="text-2xl text-white font-nebulica font-bold mb-4">
          Iniciar Sesión
        </h2>

        {/* Formulario */}
        <Form
          onSwitchToSignUp={handleSwitchToSignUp}
          onSignInSuccess={handleSignInSuccess} // Pasa la función al Form
        />
      </div>
    </div>
  );
}

export default SignIn;
