import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import PasswordPopup from "./popUps/passwordPopup";
import ContinueButton from "./buttons/continueButton";
import { TimerContext } from "./timerContext_new";

// Imágenes
import backgroundImage from "../imgs/people_background.webp";
import timeRedIcon from "../imgs/timer-red.svg";
import fondoCrucigrama from "../imgs/cuartos/Paciente0/fondocrucigrama.webp";
import cuadroVerdeRelleno from "../imgs/cuartos/Paciente0/cuadroverderelleno.webp";
import cuadroVerde from "../imgs/cuartos/Paciente0/cuadroverde.webp";
import cuadroRojo from "../imgs/cuartos/Paciente0/cuadrorojo.webp";

// Iconos
import location from "../imgs/cuartos/Paciente0/location.png";

// Firebase
import { useAuth } from "./AuthProvider";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

const Crucigrama = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState("");
  const [letters, setLetters] = useState(Array(35).fill("")); // Ajusta el tamaño según el número de cuadros editables
  const navigate = useNavigate();
  const { timeLeft } = useContext(TimerContext);
  const { currentUser } = useAuth();

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handlePasswordSubmit = async (password) => {
    if (password === "Luvex") {
      try {
        const userDocRef = doc(db, "paciente_0", currentUser.uid);
        await updateDoc(userDocRef, { currentPhase: 6 });

        setTimeout(() => {
          setShowPopup(false);
          setError("");
          navigate("/Impresora");
        }, 3000);
      } catch (error) {
        console.error("Error al actualizar currentPhase en Firestore:", error);
        setError("Ocurrió un error. Intenta nuevamente.");
      }
    } else {
      setError("Contraseña incorrecta. Intenta de nuevo.");
    }
  };

  const handleInputChange = (index, value) => {
    if (value.length <= 1 && /^[A-Za-z]?$/.test(value)) {
      const newLetters = [...letters];
      newLetters[index] = value.toUpperCase();
      setLetters(newLetters);
    }
  };

  const renderSquare = (type, index) => {
    switch (type) {
      case "verde-relleno":
        return (
          <div
            key={index}
            className="w-[26px] h-[26px] bg-cover"
            style={{ backgroundImage: `url(${cuadroVerdeRelleno})` }}
          ></div>
        );
      case "verde":
        return (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={letters[index]}
            onChange={(e) => handleInputChange(index, e.target.value)}
            className="w-[26px] h-[26px] bg-cover text-center font-bold uppercase"
            style={{ backgroundImage: `url(${cuadroVerde})` }}
          />
        );
      case "rojo":
        return (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={letters[index]}
            onChange={(e) => handleInputChange(index, e.target.value)}
            className="w-[26px] h-[26px] bg-cover text-center font-bold uppercase"
            style={{ backgroundImage: `url(${cuadroRojo})` }}
          />
        );
      default:
        return null;
    }
  };

  const grid = [
    [
      "verde-relleno",
      "verde",
      "rojo",
      "verde",
      "verde",
      "verde",
      "verde-relleno",
      "verde-relleno",
      "verde-relleno",
      "verde-relleno",
    ],
    [
      "verde",
      "verde",
      "rojo",
      "verde",
      "verde",
      "verde",
      "verde",
      "verde",
      "verde",
      "verde",
    ],
    [
      "verde",
      "verde",
      "rojo",
      "verde",
      "verde",
      "verde",
      "verde",
      "verde",
      "verde",
      "verde",
    ],
    [
      "verde-relleno",
      "verde",
      "rojo",
      "verde",
      "verde",
      "verde",
      "verde",
      "verde",
      "verde-relleno",
      "verde-relleno",
    ],
    [
      "verde-relleno",
      "verde",
      "rojo",
      "verde",
      "verde",
      "verde",
      "verde",
      "verde",
      "verde-relleno",
      "verde-relleno",
    ],
  ];

  return (
    <div
      className="min-h-screen bg-blueCTCbg bg-cover bg-top bg-no-repeat flex flex-col items-center justify-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="absolute top-8 text-center">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-white text-5xl font-bold bg-redCTC px-8 py-2">
            Paciente 0
          </h1>
        </div>
        <div className="flex items-center justify-center mt-2">
          <img src={location} alt="Logo" className="w-6 h-6" />
          <p className="text-white md:text-lg text-sm font-roboto-mono">
            Aeropuerto de Guadalajara Terminal A
          </p>
        </div>
        <div className="flex items-center justify-center p-2 rounded-md shadow-md text-white font-roboto-mono">
          <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
          <span className="text-lg">{formatTime(timeLeft)}</span>
        </div>
      </div>

      <div
        className="relative flex flex-col items-center justify-center"
        style={{
          backgroundImage: `url(${fondoCrucigrama})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "500px",
          height: "500px",
        }}
      >
        <div className="grid grid-cols-10 gap-1 mt-10 ml-6">
          {grid.flat().map((type, index) => renderSquare(type, index))}
        </div>
      </div>

      <ContinueButton setShowPopup={setShowPopup} bgColor="bg-greenPaciente0" />

      {showPopup && (
        <PasswordPopup
          onSubmit={handlePasswordSubmit}
          onClose={() => setShowPopup(false)}
          titleText="Ingresa la contraseña"
          buttonText="Verificar"
          error={error}
        />
      )}
    </div>
  );
};

export default Crucigrama;
