import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { TimerContext } from "../timerContext_new";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../AuthProvider";

//PopUp que muestra las instrucciones y los términos y condiciones cuando se va a iniciar un cuarto híbrido
//Creado por: Alberto Cebreros

const TermsPopup = ({ onAccept, onClose, roomId, timeInMinutes = 60 }) => {
  const { restartTimer } = useContext(TimerContext);
  const { currentUser } = useAuth();

  const handleAccept = async () => {
    try {
      if (currentUser) {
        const userDocRef = doc(db, roomId, currentUser.uid);
        await setDoc(userDocRef, { currentPhase: 1 }, { merge: true });
      }
      restartTimer(timeInMinutes * 60);
      onAccept();
    } catch (error) {
      console.error("Error al inicializar la fase en Firestore:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50 px-4">
      <div className="relative w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow">
        <button
          className="absolute top-2 right-4 text-white text-2xl"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-2xl text-white font-nebulica font-bold mb-4">
          Instrucciones
        </h2>
        <p className="text-white mb-4">
          1. Las pistas pueden estar escondidas en cualquier parte de la web.
          ¡No te limites!
        </p>
        <p className="text-white mb-4">
          2. Cuentas con 30 minutos para finalizar el cuarto, una vez iniciado
          el cronometro no se detendrá.
        </p>
        <p className="text-white mb-4">
          3. Durante la experiencia tendrás que moverte (siempre a lugares
          permitidos) alrededor del punto inicial.
        </p>
        <p className="text-white mb-4">
          4. No es necesario tocar, modificar, ni hacer cualquier cosa dentro de
          la terminal, solo observar.
        </p>
        <p className="text-white mb-4">
          5. Aunque no hay como la adrenalina de escuchar "la última llamada",
          manténte al pendiente de las actualizaciones de tu vuelo.
        </p>
        <p className="text-white mb-4">
          6. No será fácil, solo los CRACKS encuentran la forma de terminarlo.
          ¿Eres uno de nosotros? ¡Demuéstralo!
        </p>
        <div className="flex justify-center space-x-4">
          <button
            className="bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-50"
            onClick={handleAccept}
          >
            Inicio
          </button>
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-400 focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-50"
            onClick={onClose}
          >
            No soy capaz
          </button>
        </div>
        <div className="mt-4">
          <p className="text-white mb-4">
            Al dar click en inicio, aceptas los
            <Link
              to="/terms"
              target="_blank"
              className="text-blue-300 underline mx-1"
            >
              términos y condiciones.
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsPopup;
