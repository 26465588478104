import React, { useState } from "react";
import { signInWithGoogle, signInWithEmailAndPassword } from "../../firebase";
import { useNavigate } from "react-router-dom";

//Formulario de inicio de sesión
//Creado por: Alberto Cebreros

export default function Form({ onSwitchToSignUp, onSignInSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(email, password);
      alert("Inicio de sesión exitoso");
      onSignInSuccess();
      navigate("/");
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      if (error.code === "auth/user-not-found") {
        alert("Usuario no existente. Por favor, regístrate.");
      } else if (error.code === "auth/wrong-password") {
        alert("Contraseña incorrecta. Por favor, intenta de nuevo.");
      } else {
        alert(
          "Hubo un error al iniciar sesión. Por favor, inténtalo de nuevo."
        );
      }
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithGoogle();
      onSignInSuccess();
      navigate("/");
    } catch (error) {
      console.error("Error al iniciar sesión con Google:", error);
      alert(
        "Hubo un error al iniciar sesión con Google. Por favor, inténtalo de nuevo."
      );
    }
  };

  return (
    <form onSubmit={handleLogin}>
      <div className="mb-4">
        <label htmlFor="email" className="block text-sm font-medium text-white">
          Correo Electrónico
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary"
          placeholder="Ingresa tu correo electrónico"
          required
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="password"
          className="block text-sm font-medium text-white"
        >
          Contraseña
        </label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-3 py-2 mt-1 text-sm text-zinc-900 placeholder-zinc-400 bg-input border border-zinc-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-primary"
          placeholder="Ingresa tu contraseña"
          required
        />
      </div>
      <div className="flex justify-between items-center mb-4">
        <button className="text-sm font-semibold text-redCTC">
          ¿Olvidaste tu contraseña?
        </button>
      </div>
      {/* Botones */}
      <div className="mt-8 flex flex-col gap-y-4">
        <button
          type="submit"
          className="w-full bg-redCTC text-white text-lg font-bold mb-2 py-2 px-4 rounded-md active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
        >
          Iniciar Sesión
        </button>
        <button
          type="button"
          onClick={handleGoogleLogin}
          className="flex items-center justify-center gap-2 active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out font-semibold rounded-md py-2.5 bg-white"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.26644 9.76453C6.19903 6.93863 8.85469 4.90909 12.0002 4.90909C13.6912 4.90909 15.2184 5.50909 16.4184 6.49091L19.9093 3C17.7821 1.14545 15.0548 0 12.0002 0C7.27031 0 3.19799 2.6983 1.24023 6.65002L5.26644 9.76453Z"
              fill="#EA4335"
            />
            <path
              d="M16.0406 18.0142C14.9508 18.718 13.5659 19.0926 11.9998 19.0926C8.86633 19.0926 6.21896 17.0785 5.27682 14.2695L1.2373 17.3366C3.19263 21.2953 7.26484 24.0017 11.9998 24.0017C14.9327 24.0017 17.7352 22.959 19.834 21.0012L16.0406 18.0142Z"
              fill="#34A853"
            />
            <path
              d="M19.8342 20.9978C22.0292 18.9503 23.4545 15.9019 23.4545 11.9982C23.4545 11.2891 23.3455 10.5255 23.1818 9.81641H12V14.4528H18.4364C18.1188 16.0119 17.2663 17.2194 16.0407 18.0108L19.8342 20.9978Z"
              fill="#4A90E2"
            />
            <path
              d="M5.27698 14.2663C5.03833 13.5547 4.90909 12.7922 4.90909 11.9984C4.90909 11.2167 5.03444 10.4652 5.2662 9.76294L1.23999 6.64844C0.436587 8.25884 0 10.0738 0 11.9984C0 13.918 0.444781 15.7286 1.23746 17.3334L5.27698 14.2663Z"
              fill="#FBBC05"
            />
          </svg>
          Ingresar con Google
        </button>
      </div>
      <div className="mt-4 text-center">
        <p className="text-sm text-white">
          ¿No tienes una cuenta?{" "}
          <span
            className="text-redCTC font-semibold cursor-pointer"
            onClick={onSwitchToSignUp} // Llama la función para cambiar al formulario de registro
          >
            Regístrate
          </span>
        </p>
      </div>
    </form>
  );
}
