import React from "react";
import SocialMediaIcon from "./ui/socialmediaicon";
// Importa los iconos de las redes sociales
import { GrInstagram } from "react-icons/gr";
import { FaFacebook } from "react-icons/fa";
// import { FaTwitter } from "react-icons/fa";
// import { IoLogoYoutube } from "react-icons/io";

export default function SocialMedia() {
  return (
    <div className="flex items-center justify-center space-x-4">
      <SocialMediaIcon
        text="Instagram"
        color="bg-gradient-to-tr from-yellow-500 to-purple-500 via-pink-500"
        href="https://www.instagram.com/crackinmx/"
      >
        <GrInstagram size={25} />
      </SocialMediaIcon>
      <SocialMediaIcon
        text="Facebook"
        color="bg-blue-600"
        href="https://www.facebook.com/profile.php?id=61565447450555"
      >
        <FaFacebook size={25} />
      </SocialMediaIcon>
      {/* <SocialMediaIcon text="Twitter" color="bg-blue-400">
        <FaTwitter size={25} />
      </SocialMediaIcon>
      <SocialMediaIcon text="YouTube" color="bg-red-600">
        <IoLogoYoutube size={25} />
      </SocialMediaIcon> */}
    </div>
  );
}
