import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "./AuthProvider";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../stripeConfig";
import PaymentPopup from "./popUps/paymentPopup";
import { cardData } from "../data"; // Importar cardData

import PasswordPopup from "./popUps/passwordPopup";
import ContinueButton from "./buttons/continueButton";
import { TimerContext } from "./timerContext_new";

// Imágenes
import backgroundImage from "../imgs/people_background.webp";
import timeRedIcon from "../imgs/timer-red.svg";
import maquinaDispensadora from "../imgs/cuartos/Paciente0/MaquinaDispensadora.webp";

// Iconos
import location from "../imgs/cuartos/Paciente0/location.png";

const MaquinaDispensadora = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [hasPaid, setHasPaid] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { timeLeft } = useContext(TimerContext);
  const { currentUser } = useAuth();

  // Obtener el paymentTime del cuarto "Paciente 0"
  const paciente0Data = cardData.find((room) => room.id === "h2");
  const paymentTime = paciente0Data?.paymentTime || 40; // valor por defecto de 40 si no se encuentra

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (currentUser) {
        const docRef = doc(db, "paciente_0", currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const userHasPaid = data.hasPaid || false;
          setHasPaid(userHasPaid);
          setShowPaymentPopup(!userHasPaid);
        } else {
          setShowPaymentPopup(true);
        }
      }
    };

    checkPaymentStatus();
  }, [currentUser]);

  const handlePasswordSubmit = async (password) => {
    if (password === "D6") {
      try {
        const userDocRef = doc(db, "paciente_0", currentUser.uid);
        await updateDoc(userDocRef, { currentPhase: 4 });

        setTimeout(() => {
          setShowPopup(false);
          setError("");
          navigate("/Prescripcion");
        }, 3000);
      } catch (error) {
        console.error("Error al actualizar currentPhase en Firestore:", error);
        setError("Ocurrió un error. Intenta nuevamente.");
      }
    } else {
      setError("Contraseña incorrecta. Intenta de nuevo.");
    }
  };

  return (
    <div
      className="min-h-screen bg-blueCTCbg bg-cover bg-top bg-no-repeat flex flex-col items-center justify-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {showPaymentPopup && !hasPaid && (
        <Elements stripe={stripePromise}>
          <PaymentPopup
            onClose={() => setShowPaymentPopup(false)}
            collectionName="paciente_0"
            phaseNumber={3}
            amount={79}
            originalAmount={189}
            paymentTime={paymentTime}
          />
        </Elements>
      )}

      <div className="absolute top-8 text-center">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-white text-5xl font-bold bg-redCTC px-8 py-2">
            Paciente 0
          </h1>
        </div>
        <div className="flex items-center justify-center mt-2">
          <img src={location} alt="Logo" className="w-6 h-6" />
          <p className="text-white md:text-lg text-sm font-roboto-mono">
            Aeropuerto de Guadalajara Terminal A
          </p>
        </div>
        <div className="flex items-center justify-center p-2 rounded-md shadow-md text-white font-roboto-mono">
          <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
          <span className="text-lg">{formatTime(timeLeft)}</span>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center cursor-pointer max-w-xl mt-48 mb-12 mx-4">
        <img
          src={maquinaDispensadora}
          alt="Maquina Dispensadora"
          className="w-full"
        />
        <ContinueButton
          setShowPopup={setShowPopup}
          bgColor="bg-greenPaciente0"
        />
      </div>
      {showPopup && (
        <PasswordPopup
          onSubmit={handlePasswordSubmit}
          onClose={() => setShowPopup(false)}
          titleText="Ingresa la contraseña"
          buttonText="Verificar"
          error={error}
        />
      )}
    </div>
  );
};

export default MaquinaDispensadora;
