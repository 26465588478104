import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "./AuthProvider";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../stripeConfig";
import PaymentPopup from "./popUps/paymentPopup";

import fondoClub42 from "../imgs/cuartos/club42/fondo_club42.webp";
import logoClub42 from "../imgs/cuartos/club42/logo.png";
import barIcon from "../imgs/cuartos/club42/bar.svg";
import ContinueButtonGold from "./buttons/continueButtonGold";
import { TimerContext } from "./timerContext_new";
import timeRedIcon from "../imgs/timer-red.svg";
import PasswordPopup from "./popUps/passwordPopup";
import { cardData } from "../data";
const MelodiaIdentificada = () => {
  const { timeLeft } = useContext(TimerContext);
  const [showPopup, setShowPopup] = useState(false);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [hasPaid, setHasPaid] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const melodiaIdentificadaData = cardData.find((room) => room.id === "v3");
  const paymentTime = melodiaIdentificadaData?.paymentTime || 40; // valor por defecto de 40 si no se encuentra

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (currentUser) {
        const docRef = doc(db, "club_42", currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const userHasPaid = data.hasPaid || false;
          setHasPaid(userHasPaid);
          setShowPaymentPopup(!userHasPaid);
        } else {
          setShowPaymentPopup(true);
        }
      }
    };

    checkPaymentStatus();
  }, [currentUser]);

  const handlePasswordSubmit = async (password) => {
    if (password === "2") {
      try {
        if (currentUser) {
          const userDocRef = doc(db, "club_42", currentUser.uid);
          await updateDoc(userDocRef, { currentPhase: 4 });
        }
        setShowPopup(false);
        setError("");
        navigate("/menu");
      } catch (error) {
        console.error("Error al actualizar currentPhase en Firestore:", error);
        setError("Ocurrió un error. Por favor, intenta de nuevo.");
      }
    } else {
      setError("Incorrecto. Intenta de nuevo.");
    }
  };

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center text-white"
      style={{
        backgroundImage: `url(${fondoClub42})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Timer */}
      <div className="absolute top-4 right-4 flex items-center bg-blueCTC p-2 rounded-md shadow-md text-white font-bold">
        <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
        <span className="text-lg">{formatTime(timeLeft)}</span>
      </div>

      {showPaymentPopup && !hasPaid && (
        <Elements stripe={stripePromise}>
          <PaymentPopup
            onClose={() => setShowPaymentPopup(false)}
            collectionName="club_42"
            phaseNumber={3}
            amount={79}
            originalAmount={189}
            paymentTime={paymentTime}
          />
        </Elements>
      )}

      {/* Logo */}
      <div className="my-24">
        <img src={logoClub42} alt="Club 42 Logo" className="w-48 h-auto" />
      </div>

      {/* Texto principal */}
      <div className="text-center px-8">
        <p className="text-xl md:text-2xl mb-4">
          El <span className="font-bold">BARMAN</span> del Bar a tu derecha
          escuchó la melodía que acabas de entonar.
        </p>
        <p className="text-xl md:text-2xl mb-4">
          Reconociendo la melodía secreta, te invita a su barra.
        </p>
        <p className="text-sm italic">
          *Observa con atención el logo. Ingresa la cantidad de estrellas.
        </p>
      </div>

      {/* Imagen central */}
      <div className="my-8">
        <img src={barIcon} alt="Barra" className="w-64 h-auto" />
      </div>

      {/* Botón de continuar */}
      <ContinueButtonGold setShowPopup={setShowPopup} />

      {/* Mostrar el PopUp de contraseña si está abierto */}
      {showPopup && (
        <PasswordPopup
          onSubmit={handlePasswordSubmit}
          onClose={() => setShowPopup(false)}
          titleText="Ingresa el número de estrellas"
          placeholderText="Número de estrellas"
          buttonText="Verificar"
          error={error}
        />
      )}
    </div>
  );
};

export default MelodiaIdentificada;
