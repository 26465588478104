// data.js
import persona from "./imgs/persona.png";
import club42 from "./imgs/Club-42.webp";
import paciente0 from "./imgs/Paciente-0-CTC.webp";
import puerta33 from "./imgs/Puerta-33.webp";
import asesinoImitador from "./imgs/Asesino-Imitador.webp";
import u187b from "./imgs/U187-B.webp";

export const testimonials = [
  {
    id: 1,
    name: "Jacqueline S.",
    img: persona,
    description:
      "En mi experiencia personal los escape rooms virtuales fueron una dinámica increíble para convivir con mis amigas. Haberlo hecho de manera virtual hizo que se convirtiera aún más retador y desafiante, lo cual pudimos disfrutar juntas. Me ayudaron a desafiar mi mente y a desarrollar nuevas habilidades.",
    rating: 5,
    cuarto: "Personalizado",
  },
  {
    id: 2,
    name: "Isaac A.",
    img: persona,
    description:
      "Me encanto hacer el room porque te hace pensar y salirte de zona de confort haciendo una actividad diferente y divertida donde usas mucho tus habilidades",
    rating: 5,
    cuarto: "Asesino Imitador",
  },
  {
    id: 3,
    name: "MARCK B",
    img: persona,
    description:
      "Me encanta como mientras vas avanzando no se pierde la historia y te siguen dando contexto y más datos para envolverte en ella! Una experiencia increíble!",
    rating: 5,
    cuarto: "Asesino Imitador",
  },
  {
    id: 4,
    name: "Luis M.",
    img: persona,
    description:
      "Mucho más difícil de lo que pensé, no pude terminarlo a tiempo",
    rating: 3,
    cuarto: "The Big Brother",
  },
  {
    id: 5,
    name: "Pablo R.",
    img: persona,
    description:
      "Esta muy entretenido como cada pista te acerca más a encontrar la identidad del asesino.",
    rating: 5,
    cuarto: "Asesino Imitador",
  },
  {
    id: 6,
    name: "Javier G.",
    img: persona,
    description:
      "Es de lo más diferente que he hecho con mis amigos en mucho tiempo. Muy retador, no es para cualquiera",
    rating: 4,
    cuarto: "Asesino Imitador",
  },
  {
    id: 7,
    name: "Fernanda G.",
    img: persona,
    description:
      "Sentí que acabo de hacerme una prueba de IQ. Desafió mi mente y me obligó a resolver problemas de maneras que no pensé que se podían.",
    rating: 4,
    cuarto: "The Big Brother",
  },
  {
    id: 8,
    name: "Miguel E.",
    img: persona,
    description:
      "Nunca había hecho un escape room virtual. El cuarto de Big brother estuvo muy divertido y me obligó a pensar de manera estratégica.",
    rating: 5,
    cuarto: "The Big Brother",
  },
  {
    id: 9,
    name: "Miguel K.",
    img: persona,
    description:
      "La dinámica del escape room adaptada a nuestra marca fue única. Fue una forma divertida y original de fortalecer la colaboración y motivar al equipo.",
    rating: 5,
    cuarto: "Personalizados",
  },
  {
    id: 10,
    name: "Hugo P.",
    img: persona,
    description:
      "Tuvimos un escape room con referencias a nuestra compañía. Me encantó la creatividad y cómo nos hizo trabajar juntos para resolver cada pista.",
    rating: 5,
    cuarto: "Personalizados",
  },
  {
    id: 11,
    name: "Jose S.",
    img: persona,
    description: "Una de las maneras más originales de hacer Team Building",
    rating: 5,
    cuarto: "Personalizados",
  },
  {
    id: 12,
    name: "Carlos L.",
    img: persona,
    description:
      "Es increíble ver la cantidad de creatividad que tienen para adaptar un escape room completo alrededor de una marca",
    rating: 5,
    cuarto: "Personalizados",
  },
  {
    id: 13,
    name: "LIZETH K",
    img: persona,
    description:
      "Participar fue una experiencia bastante divertida y desafiante. La dinámica del juego me obligó a trabajar en equipo y pensar fuera de la caja, siendo una forma original y muy distinta de conectar y desafiarnos. Si buscas una experiencia única para ponerte a prueba, no te decepcionará",
    rating: 5,
    cuarto: "Personalizado",
  },
  {
    id: 14,
    name: "MARCK B",
    img: persona,
    description:
      "Nunca me imaginé que habrían pistas en tantas redes sociales y aplicaciones diferentes fue una experiencia increíble!!",
    rating: 5,
    cuarto: "The Big Brother",
  },
];

export const cardData = [
  {
    id: "h1",
    nombre: "Club 42",
    dificultad: "DIFICULTAD: 🔵🔵🔵🔵⚪",
    imagen: club42,
    sinopsis:
      "Encuentra el Club 42: el lugar donde se reúne la élite de nuestra sociedad. ¿Estás listo para superar los desafíos que te separan de los mejores?",
    lugar:
      "Aeropuerto Internacional de la Ciudad de México, terminal 1. Sala 19",
    distancia: "100 metros",
    tiempo: "30 min",
    ciudad: "CDMX",
    terminal: "AICM 1",
    estado: "Crack-In",
    timeInMinutes: 30,
    paymentTime: 30,
  },
  {
    id: "h2",
    nombre: "Paciente 0",
    dificultad: "DIFICULTAD: 🔵🔵🔵🔵⚪",
    imagen: paciente0,
    sinopsis:
      "Una pandemia global amenaza nuestra existencia. Solo tú puedes detenerla. ¿Tienes lo que se necesita para asumir esta misión vital y salvar a la humanidad?",
    lugar: "Aeropuerto de Guadalajara, terminal A",
    distancia: "50 metros",
    tiempo: "30 min",
    ciudad: "Guadalajara",
    terminal: "GDL A",
    estado: "Crack-In",
    timeInMinutes: 30,
    paymentTime: 30,
  },
  {
    id: "h3",
    nombre: "Vuelo Secreto",
    dificultad: "DIFICULTAD: 🔵🔵🔵⚪⚪",
    imagen: puerta33,
    sinopsis:
      "Embarca hacia uno de los destinos más exclusivos. Descubre un vuelo secreto y asegura tu lugar con tu perspicacia. ¿Estás listo para vencer al sistema?",
    lugar: "Aeropuerto de Monterrey, Terminal A",
    distancia: "100 metros",
    tiempo: "30 min",
    ciudad: "Monterrey",
    terminal: "MTY A",
    estado: "Próximamente",
    timeInMinutes: 30,
    paymentTime: 30,
  },
];

export const cardDataVirtuales = [
  {
    id: "v1",
    nombre: "Asesino Imitador",
    imagen: asesinoImitador,
    sinopsis:
      "Adéntrate en el oscuro mundo de un asesino serial y se rival de su genialidad. Solo alguien igual de capaz que él podrá detenerlo. ¿Serás tú el indicado?",
    lugar: "100% virtual",
    tiempo: "60 min",
    dificultad: "DIFICULTAD: 🔵🔵🔵🔵⚪",
    timeInMinutes: 60,
    paymentTime: 40,
  },
  {
    id: "v2",
    nombre: "The Big Brother",
    imagen: u187b,
    sinopsis:
      "Enfréntate a una misión crítica en un universo paralelo: desactivar una bomba que aniquilará el planeta Tierra. ¿Tienes lo necesario para salvar este universo?",
    lugar: "100% virtual",
    tiempo: "60 min",
    dificultad: "DIFICULTAD: 🔵🔵🔵🔵⚪",
    timeInMinutes: 60,
    paymentTime: 40,
  },
];

export const faqsBySection = [
  {
    title: "Escape Rooms Virtuales e Híbridos",
    questions: [
      {
        question:
          "¿Qué necesito para jugar a un escape room virtual o híbrido?",
        answer:
          "Para participar en nuestros escape rooms, solo necesitas un dispositivo inteligente, conexión a internet, y en algunos casos, acceso a redes sociales.",
      },
      {
        question:
          "¿Cuál es la diferencia entre un escape room virtual y uno híbrido?",
        answer:
          "Los escape rooms virtuales se pueden jugar desde cualquier lugar con conexión a internet. Por otro lado, los escape rooms híbridos requieren que interactúes con el entorno físico a tu alrededor para resolver los enigmas.",
      },
      {
        question: "¿Cómo funciona un escape room híbrido en un aeropuerto?",
        answer:
          "En los escape rooms híbridos, interactúas con elementos del mundo real mientras resuelves pistas en un entorno digital. Esta fusión crea una experiencia inmersiva que transforma cualquier lugar en parte del juego.",
      },
      {
        question:
          "¿Puedo jugar un escape room híbrido en grupo en el aeropuerto?",
        answer:
          "Sí, puedes jugar en grupo utilizando un solo dispositivo. Si prefieres competir, te sugerimos que cada persona use su propio dispositivo (se paga por dispositivo)",
      },
      {
        question:
          "¿Los escape rooms híbridos en aeropuertos se realizan antes o después del filtro de seguridad?",
        answer:
          "Nuestros escape rooms híbridos están diseñados para realizarse después del filtro de seguridad, para que puedas disfrutarlos mientras esperas tu vuelo.",
      },
      {
        question: "¿En qué aeropuertos están disponibles los escape rooms?",
        answer:
          "Actualmente, estamos en AICM T1, MTY TA, y GDL Puerta A. Próximamente estaremos en Cancún T1, T2, T3; AICM T2, GDL Puerta C, y AIFA.",
      },
      {
        question:
          "¿Dónde se encuentran los puntos de inicio de los escape rooms híbridos en los aeropuertos?",
        answer:
          "Los puntos de inicio se ubican en áreas centrales de las salas de abordaje. La ubicación exacta varía según el escape room, pero siempre estará cerca de donde necesitas estar.",
      },
      {
        question: "¿Cuánto tiempo duran los escape rooms híbridos y virtuales?",
        answer:
          "Cuentas con 40 minutos para terminar los cuartos híbridos, mientras que los virtuales tienen un límite de 60 minutos.",
      },
      {
        question:
          "¿Cuánto tiempo antes de mi vuelo debo llegar para participar en un escape room híbrido?",
        answer:
          "Se recomienda llegar con 2 horas de anticipación para vuelos nacionales y 3 horas para internacionales. Asegúrate de iniciar el juego al menos 40 minutos antes de que comience el abordaje de tu vuelo.",
      },
      {
        question:
          "¿Qué tipo de desafíos puedo esperar en un escape room híbrido en el aeropuerto?",
        answer:
          "Encontrarás acertijos visuales, matemáticos, lógicos y más, diseñados para poner a prueba diversas habilidades, como la inteligencia kinestésica, lógica, emocional y visual.",
      },
      {
        question:
          "¿Qué tan inmersiva es la experiencia de un escape room híbrido en un aeropuerto?",
        answer:
          "Nuestra experiencia de escape room híbrido te sumerge completamente en la aventura. Al combinar pistas digitales con elementos físicos del entorno, como señales, objetos y espacios del aeropuerto, logramos que te sientas parte de la historia. Desde el momento en que comienzas, cada rincón del aeropuerto se convierte en parte del juego, transformando tu espera en una experiencia desafiante e inolvidable.",
      },
    ],
  },
  {
    title: "Escape Rooms Personalizados y Eventos Corporativos",
    questions: [
      {
        question:
          "¿Se pueden adaptar los escape rooms virtuales para alinearse con los valores o misión de una institución?",
        answer:
          "Absolutamente. Diseñamos nuestros escape rooms virtuales desde cero, hechos a la medida de las necesidades de tu organización.",
      },
      {
        question:
          "¿Se puede usar un escape room virtual personalizado como parte de un programa de formación empresarial?",
        answer:
          "Por supuesto, es una excelente herramienta para reforzar aprendizajes y fomentar la cohesión del equipo.",
      },
      {
        question: "¿Puedo hacer mi evento corporativo internacional?",
        answer:
          "Sí, contamos con amplia experiencia trabajando con equipos de trabajo regionales simultáneamente.",
      },
      {
        question:
          "¿Cuántas personas pueden participar en un escape room virtual personalizado al mismo tiempo?",
        answer:
          "Podemos personalizar cuartos para grupos desde 4 hasta 500 personas.",
      },
      {
        question:
          "¿Qué tipos de temáticas se pueden personalizar en un escape room virtual para empresas?",
        answer:
          "Podemos desarrollar cualquier temática que tengas en mente para adaptarla a las necesidades de tu evento.",
      },
      {
        question:
          "¿Cuánto tiempo se tarda en desarrollar un escape room virtual personalizado para una empresa?",
        answer:
          "El desarrollo de una actividad de escape room personalizada toma en promedio 14 días.",
      },
    ],
  },
  {
    title: "Información Técnica y Operativa",
    questions: [
      {
        question:
          "¿Es necesario descargar una aplicación para jugar en un escape room híbrido en el aeropuerto?",
        answer: "No, puedes jugar directamente desde nuestra página web.",
      },
      {
        question:
          "¿Qué tipo de equipo o dispositivos son necesarios para participar en un escape room híbrido en el aeropuerto?",
        answer:
          "Necesitas de cualquier dispositivo inteligente con acceso a internet, como un teléfono, tableta, o laptop.",
      },
      {
        question:
          "¿Los escape rooms híbridos en aeropuertos están disponibles en varios idiomas?",
        answer: "Sí, los escape rooms están disponibles en español e inglés.",
      },
      {
        question:
          "¿Cuáles son los horarios de operación de los escape rooms híbridos en los aeropuertos?",
        answer:
          "Nuestros escape rooms híbridos están disponibles las 24 horas del día, los 365 días del año, sin necesidad de reserva.",
      },
    ],
  },
  {
    title: "Seguridad y Emergencias",
    questions: [
      {
        question:
          "¿Es seguro jugar en un escape room híbrido después del filtro de seguridad en el aeropuerto?",
        answer:
          "Sí, todas las pistas se encuentran en áreas accesibles para los pasajeros con boleto, sin necesidad de acceder a zonas restringidas o de infringir ninguna ley.",
      },
      {
        question: "¿Qué hago si necesito detener el juego por una emergencia?",
        answer:
          "Puedes cerrar el juego en cualquier momento. Si tienes una emergencia, contáctanos para revisar tu caso y ver cómo podemos apoyarte, ya sea con reembolso u ofertas especiales.",
      },
    ],
  },
  {
    title: "Costos y Métodos de Pago",
    questions: [
      {
        question:
          "¿Cuánto cuesta participar en un escape room híbrido en un aeropuerto en México?",
        answer:
          "Ofrecemos una versión gratuita que incluye las primeras secciones del escape room. Para acceder al contenido completo, debes iniciar sesión y realizar un pago de XX USD.",
      },
      {
        question: "¿Qué tipo de pago aceptan?",
        answer:
          "Aceptamos tarjetas de crédito, débito, PayPal, Apple Pay, y Google Pay. Próximamente aceptaremos pagos en efectivo.",
      },
    ],
  },
  {
    title: "Otras Preguntas Comunes",
    questions: [
      {
        question: "¿Cómo sé qué escape room está disponible cerca de mí?",
        answer:
          "Puedes ingresar tu número de vuelo en nuestro sitio web para consultar la disponibilidad de escape rooms en tu ubicación.",
      },
      {
        question: "¿Qué hago con mi equipaje de mano?",
        answer:
          "Tu equipaje no debería ser un problema durante el juego. Para mayor comodidad, recomendamos dejarlo encargado con un conocido si es posible.",
      },
      {
        question:
          "¿Qué pasa si no puedo terminar el escape room híbrido antes de mi vuelo?",
        answer:
          "El acceso se bloqueará automáticamente cuando tu vuelo esté listo para abordar.",
      },
      {
        question:
          "¿Puedo abandonar el escape room híbrido en cualquier momento si necesito abordar mi vuelo?",
        answer:
          "Sí, simplemente cierra la ventana del juego y procede a abordar tu vuelo.",
      },
      {
        question:
          "¿Qué sucede si me atraso en el escape room y pierdo mi vuelo?",
        answer:
          "Nuestra plataforma está diseñada para notificarte cuando tu vuelo está por abordar, pero es tu responsabilidad estar atento a los anuncios.",
      },
      {
        question:
          "¿Cómo puedo obtener ayuda durante el juego en un escape room híbrido en el aeropuerto?",
        answer:
          "Dentro de la aplicación encontrarás un botón de ayuda para resolver cualquier duda que surja durante el juego.",
      },
    ],
  },
];

export const termsAndConditions = [
  {
    title: "Información legal",
    content: `La Aplicación y El Sitio son propiedad intelectual de “INNOVACIÓN EN ENTRETENIMIENTO AS-BH”, SOCIEDAD CIVIL (en adelante: La Empresa); sociedad legalmente constituida conforme a las leyes de los Estados Unidos Mexicanos (y, específicamente, conforme al Código Civil para el Estado Libre y Soberano de Puebla), con los siguientes datos de contacto: <br /><br />
    - <strong>Domicilio:</strong> calle 7 (siete) Sur, número 3702 (tres mil setecientos dos), Interior 5 A (cinco, “A”), colonia Gabriel Pastor, código postal 72420 (setenta y dos mil cuatrocientos veinte), Puebla, Puebla. <br />
    - <strong>Correo electrónico:</strong> admin@crackin.com.mx <br />
    - <strong>Teléfono:</strong> +525568068983`,
  },
  {
    title: "Uso del servicio",
    content:
      "Cualquier persona puede acceder a La Aplicación y El Sitio (en adelante referidos conjuntamente como: Los Servicios) como invitado, sin embargo, para hacer uso de la mayoría de las funciones ofertadas por Los Servicios es necesario cumplir con una serie de requisitos indispensables, como llevar a cabo el proceso de registro para la creación de una cuenta, contar con un dispositivo electrónico compatible y tener una conexión a internet estable. Sin embargo, el usuario podrá acceder a una versión gratuita de El Sitio y/o La Aplicación (para la cual no se requerirá iniciar sesión ni crear una cuenta) que se encuentra limitada en diferentes aspectos, siendo una prueba del resto de contenido de Los Servicios al que se podrá acceder mediante el pago de una suscripción (dependiendo del nivel de suscripción seleccionado) o a través de un pago único.",
  },
  {
    title: "Creación de cuenta",
    content: `Conforme a lo descrito con anterioridad, para acceder a la mayoría de las funciones que ofrecen Los Servicios es necesario contar con una cuenta en aquéllos, misma que será creada a través del llenado (personal y bajo estricta responsabilidad del usuario) de un formulario, en el cual el usuario deberá proporcionar algunos datos personales como su correo electrónico, nombre(s), apellidos, edad, la creación de una contraseña y de un nombre de usuario, entre otros. Durante este proceso de registro, el usuario se compromete a proporcionar únicamente datos propios, exactos y verdaderos. La Empresa se reserva el derecho de solicitar datos adicionales con el fin de corroborar la identidad del usuario. <br /><br />
    La cuenta es propiedad de La Empresa, pero su uso personal e intransferible se encuentra a cargo del usuario, por ende, éste será responsable de cualquier actividad realizada a través de la cuenta, ya sea que ésta se produzca con o sin su consentimiento. El usuario no podrá compartir la información de su cuenta, especialmente lo concerniente al correo electrónico con el que se registró y la contraseña creada para acceder. El usuario es el único responsable de adoptar las medidas de seguridad suficientes para proteger el acceso a su cuenta personal, incluyendo la creación de una contraseña segura (al menos 12 caracteres, uso de mayúsculas, símbolos, números, etcétera) y su renovación periódica, prácticas seguras durante la navegación en internet, el uso de software antivirus y antiphishing, así como estar al tanto sobre cualquier actividad sospechosa para, en caso de detectarla, cambiar la contraseña de manera inmediata o ponerse en contacto con La Empresa.`,
  },
  {
    title: "Restricción de las funciones de Los Servicios",
    content: `Las funciones de Los Servicios están disponibles para personas mayores de edad (18 años en adelante), por lo que, si una persona menor de dicha edad desea hacer uso de los mismos, deberá hacerlo previa autorización y bajo la supervisión de quien o quienes ejerzan la patria potestad, quienes, a su vez, deberán estar de acuerdo en cumplir los presentes Términos y Condiciones.
    Las funciones de Los Servicios también podrán limitarse para aquellas personas que hayan proporcionado datos engañosos u ofensivos durante su proceso de registro o posterior al mismo, pudiendo tomarse medidas que van desde cambiar unilateralmente su nombre de usuario, hasta dar de baja por completo la cuenta creada. <br /><br />
    La Empresa se reserva el derecho a dar de baja, restringir o inhabilitar todas aquellas cuentas que incumplan (a discrecionalidad de La Empresa) con lo establecido en los presentes Términos y Condiciones, sin generar ninguna clase de responsabilidad para La Empresa, incluyendo la exención de cualquier tipo de reembolso sobre los pagos ya efectuados. Todo usuario que se ubique en cualquiera de estos supuestos estará impedido para la creación o registro de una nueva cuenta, por lo que su solicitud para dicha acción será rechazada o, en su defecto, su nueva cuenta será cancelada. <br /><br />
    En concordancia con los principios éticos que no rigen La Empresa, en ninguna circunstancia se negará el acceso o el uso de las funciones de Los Servicios debido a género, religión, capacidad, raza, apariencia física, lugar de origen, idioma, estado socioeconómico, ni cualquier otra característica inherente a la personalidad, con excepción de la edad requerida para la disponibilidad de las funciones de Los Servicios, así como, en su caso, el contenido al que sólo pueda accederse onerosamente.`,
  },
  {
    title: "Descripción de las funciones de Los Servicios",
    content: `Los Servicios ofrecen una experiencia única de entretenimiento a través de Escape Rooms virtuales, los cuales están diseñados para integrarse con el entorno físico del aeropuerto1. Su principal objetivo es que todos aquellos viajeros que se encuentren esperando la salida de su vuelo puedan disfrutar de diferentes aventuras interactivas en las cuales deberán resolver acertijos relacionados con la observación de su entorno físico para, de esta forma, encontrar las respuestas que les permitan avanzar en el juego. Se considerará que el juego ha sido completado o superado una vez se resuelvan todos los acertijos presentados antes de que se agote el tiempo (aproximadamente 30 minutos) o comience el abordaje de sus vuelos. <br /><br />
    Dependiendo de la modalidad en la que el usuario decida jugar (gratuito o de pago) y el tipo de pago realizado (de suscripción o único), el usuario tendrá acceso a uno o varios Escape Rooms, los cuales pueden tener un tema diferente, relacionado con la experiencia de viaje o la cultura local del lugar donde se ubique el aeropuerto. Además, cada uno de estos Escape Rooms puede tener un nivel de dificultad distinto, mismo que podrá ser mostrado al usuario antes de comenzar la partida. Los jugadores (usuarios) pueden o no recibir recompensas virtuales por lograr completar o superar cada Escape Room o simplemente por participar; sin embargo, dichas recompensas son exclusivamente virtuales y no tienen ningún valor monetario o de canje. <br /><br />
    El juego se encuentra diseñado para utilizarse en un solo dispositivo; no obstante, el número de jugadores (usuarios) participantes puede ser indeterminado, en función de las personas que decidan interactuar con el mismo. <br /><br />
    El usuario en todo momento deberá cuidar su comportamiento, respetando tanto a los demás usuarios como, en general, a las demás personas y autoridades. De igual manera, también deberá cuidar la interacción física entre las personas para evitar malos comportamientos, contribuyendo de esta forma a una convivencia sana, segura, legal y entretenida.`,
  },
  {
    title: "Código de conducta",
    content: `Cualquier persona que acceda a cualquiera de Los Servicios o haga uso de las funciones de Los Servicios deberá sujetarse a las siguientes reglas: <br /><br />
    - Durante su acceso y/o uso, el usuario se obliga a acatar las leyes, reglamentos y demás normatividad aplicable y vigente que rijan en su entorno (especialmente los relacionados a las instalaciones de los aeropuertos, a la seguridad aeroportuaria, a los espacios restringidos y a los derechos de terceros). En el entendido de que el usuario es el único e íntegro responsable de su conducta; por lo que no existirá responsabilidad alguna ni se involucrarán de manera directa o indirecta a La Empresa por las violaciones o conductas indebidas que el usuario cometa. <br /><br />
    - Cada aeropuerto puede tener restricciones específicas en cuanto al uso de dispositivos móviles y las zonas restringidas para el tránsito de personas; por lo tanto, el usuario deberá conocer y acatar todas las normas e indicaciones, especialmente las provenientes de autoridades, haciendo caso a todas sus instrucciones y recomendaciones. El acceso y/o uso de Los Servicios y sus funciones, por ningún motivo podrá alegarse como razón o motivo para el incumplimiento de normas e indicaciones; asimismo, el incumplimiento de cualquier normatividad aplicable será responsabilidad única y exclusiva del usuario, quien se obliga a indemnizar a La Empresa por el incumplimiento a lo aquí convenido. <br /><br />
    - Queda estrictamente prohibido hacer uso de trampas (cheats), así como aprovecharse de vulnerabilidades (exploits) o fallos (bugs) que pudieran ocasionar algún comportamiento inesperado que pudiera beneficiar al usuario. En caso de identificar alguno de estos errores, el usuario deberá notificar directamente, y de inmediato, a La Empresa a través de cualquiera de los medios de contacto contenidos en estos Términos y Condiciones. <br /><br />
    - En vista de la interacción que puede haber con otras personas, ya sea de manera física o virtual, se prohíbe estrictamente el comportamiento que implique o pudiera generar cualquier tipo de discriminación, acoso, suplantación, ofensa, estafa y, en general, toda aquella con el fin de perjudicar a otras personas y/o a La Empresa; el incumplimiento de lo anterior será responsabilidad única y exclusiva del usuario, quien, en su caso, se obliga a indemnizar a La Empresa en caso de que ésta resulte demandada. <br /><br />
    - Queda prohibido tener más de una cuenta por usuario para acceder a las funciones de Los Servicios. <br /><br />
    Las políticas establecidas en el presente código de conducta pueden ser revisadas y actualizadas conforme sea requerido para afrontar todos aquellos nuevos desafíos que surjan, garantizado de esta forma la vigencia del mismo y su efectividad para la creación de un entorno de juego seguro y positivo.`,
  },
  {
    title: "Contenido",
    content: `Todo el contenido de Los Servicios, sin limitación alguna e incluyendo su funcionalidad y características, pertenecen a La Empresa; siendo protegidos (dichos derechos) por las leyes mexicanas en materia de derechos de autor y registro de marcas. En ninguna circunstancia se considerará que los usuarios adquieran la propiedad de Los Servicios (o de alguna parte de éstos o de sus funciones) al suscribirse o realizar cualquier pago, pues lo anterior únicamente representa la adquisición de una licencia personal, limitada y revocable para el uso exclusivo de Los Servicios, sus funciones y su cuenta, sujetos todos ellos a los presentes Términos y Condiciones, licencia que no puede ser transferida ni comercializada por otro ente o persona salvo La Empresa.<br /><br />
    A cualquier persona que infrinja lo anterior, además de aplicar las sanciones correspondientes para dar de baja, restringir o inhabilitar su cuenta, también se procederá por la vía legal correspondiente conforme a las leyes aplicables, ya sean de carácter civil, mercantil y/o penal. Se exceptúa de lo anterior (y, por ende, se permite) compartir imágenes o videos que constituyan una copia fiel de contenido observable dentro de Los Servicios, siempre y cuando su finalidad no sea la obtención de un lucro, se conserve la visibilidad de la marca y se proporcionen los créditos necesarios.`,
  },
  {
    title: "Publicidad",
    content: `Durante el uso de Los Servicios es posible que el usuario experimente la presencia de anuncios que pueden ser patrocinados por diferentes marcas comerciales, empresas, industrias o personas, ofertando productos y servicios que pudieran (o no) ser del interés de los usuarios conforme a la información disponible que se tiene de ellos (en especial cookies). Lo anterior no significa la venta de información hacia las personas señaladas, pues en caso de que estas últimas requieran cualquier tipo de personalización para una mejor selección de su público objetivo, la única información a compartir será aquella que no comprometa la identidad personal de los usuarios, por ejemplo, estadísticas de uso, tendencias de compra y comportamiento, datos demográficos generales, etcétera. <br /><br />
    Es posible que la mayoría de estos anuncios provengan de terceros que no se encuentran relacionados directamente con La Empresa y contengan enlaces externos, por lo tanto, La Empresa no se hace responsable de la disponibilidad, contenido o calidad de los productos o servicios ofertados a través de los mismos, ni de la seguridad de los sitios o transacciones que, en ellos, o por ellos, se genere. La decisión final de interactuar con la publicidad es responsabilidad total y única del usuario.`,
  },
  {
    title: "Actualización de contenido",
    content: `El contenido de Los Servicios y de las cuentas puede ser actualizado, modificado o eliminado a discreción de La Empresa, sin necesidad de previo aviso o explicación a los usuarios. La Empresa no será responsable de establecer una periodicidad o frecuencia de actualizaciones, pues éstas serán realizadas únicamente cuando se considere necesario (a juicio de La Empresa). Asimismo, La Empresa no será responsable por los daños o pérdidas que pudieran generarse a partir de la implementación de dichos cambios, actualizaciones, modificaciones o eliminaciones. <br /><br />
    Aunque exista un esfuerzo por parte de La Empresa para notificar los cambios realizados en el contenido, La Empresa se reserva el derecho a implementarlos de manera repentina y sin previo aviso, resultando en la obligación del usuario el verificar periódicamente los cambios realizados con el fin de mantenerse informados.`,
  },
  {
    title: "Disponibilidad del contenido",
    content: `Los técnicos y desarrolladores de La Empresa procuran el buen funcionamiento de Los Servicios y sus funciones, sin embargo, es posible que surjan situaciones que afecten Los Servicios y sus funciones, ya sea atribuibles a ellos mismos o a un tercero (por ejemplo, compañías de internet, proveedores de servidores, suministradores de energía, desastres naturales, enlaces externos, medios de pago, etcétera). Al momento de presentarse alguna de estas situaciones de interrupción, falla y/o suspensión en Los Servicios y sus funciones, La Empresa podrá notificar a los usuarios acerca de dichos problemas. <br /><br />
    Como parte del esfuerzo de La Empresa por mantener el buen funcionamiento de Los Servicios y sus funciones, es posible que se realicen servicios de mantenimiento programado; para lo cual La Empresa podrá informar a los usuarios sobre esta clase de interrupciones. Con los presentes Términos y Condiciones, los usuarios aceptan que el uso de Los Servicios y sus funciones conlleva sobrellevar y tolerar estas posibles interrupciones; por lo tanto, La Empresa no tendrá responsabilidad alguna derivada de, o generada por, dichas situaciones. La Empresa, en la medida de sus posibilidades, procurará adoptar las medidas correctivas necesarias y/o prudentes para enmendar estas situaciones de interrupción lo más rápido posible o limitar de cualquier forma el problema, incluyendo acciones como implementar soluciones temporales, implementar actualizaciones o establecer comunicación directa con los usuarios afectados. <br /><br />
    Si el usuario llegase a experimentar cualquier problema con Los Servicios y/o la disponibilidad de su contenido o funcionamiento, puede ponerse en contacto con La Empresa a través de los diferentes medios contenidos en estos Términos y Condiciones.`,
  },
  {
    title: "Compras y suscripciones",
    content: `En Los Servicios, el usuario tendrá la oportunidad de comprar productos virtuales, así como la adquisición de suscripciones; todos ellos utilizables únicamente dentro de Los Servicios, sin que exista la posibilidad de que, una vez adquiridos, puedan ser canjeados por dinero, bienes o cualquier otro producto fuera de Los Servicios. Para llevar a cabo cualquiera de estas compras será necesario que el usuario sea mayor de edad (18 años), de lo contrario, se deberá contar con el consentimiento de quien o quienes ejerzan la patria potestad, tutela o, en su caso, curatela correspondiente. En todo caso se deberá seguir el siguiente proceso de compra: <br /><br />
    I. Única y exclusivamente dentro de Los Servicios, se seleccionarán los productos, suscripciones o funciones que se desean adquirir de entre los ofrecidos en Los Servicios (lo cual no incluye la publicidad de terceros que, en su caso, se presente a los usuarios en Los Servicios y sus funciones). Al momento de seleccionar cada uno de éstos, se mostrarán las características principales de la compra, incluyendo su precio final, que podrá ser mostrado en pesos mexicanos o en cualquier otro tipo de moneda legal y/o virtual; también será posible que, en su caso, se muestre el tipo de cambio respectivo a la moneda. <br /><br />
    II. Una vez seleccionado el producto o servicio a comprar y estando de acuerdo con sus características y precio, el usuario deberá seleccionar el método de pago preferido entre todos los disponibles dentro de Los Servicios. <br /><br />
    III. Completado el pago, la compra realizada se verá reflejada a la brevedad posible. <br /><br />
    En el caso de las suscripciones, éstas podrán renovarse automáticamente hasta el momento de su cancelación expresa por parte del usuario, quien la podrá gestionar en cualquier momento desde la configuración de su cuenta, pero siendo efectiva hasta el final del periodo de la suscripción actual; por lo tanto, se podrán seguir disfrutando de los beneficios obtenidos por la misma hasta dicho término. Dado que existen otras alternativas para acceder a Los Servicios y sus funciones, como en su modalidad gratuita o de pago único, la cancelación de las suscripciones no tiene efecto alguno en la disponibilidad de la cuenta del usuario, salvo que esta última le sea cancelada también. <br /><br />
    Los precios finales mostrados en Los Servicios están sujetos a cambios sin previo aviso en función de diferentes factores utilizados para determinarlos, como pueden ser: la demanda del mercado, los costos de desarrollo y mantenimiento, la exclusividad o personalización de los objetos virtuales, colaboración con marcas, inflación, promociones y descuentos, competencia, entre muchos otros más. En el caso de las suscripciones, dichos cambios en el precio serán aplicados automáticamente y comenzarán a ser efectivos a partir del siguiente periodo de facturación, siendo notificados con antelación; por lo tanto, de continuar con la suscripción, se entenderá como una aceptación manifiesta a los cambios realizados. Asimismo, estos cambios no afectarán los productos o servicios que hayan sido comprados con anterioridad. <br /><br />
    Para comodidad y confianza del usuario, Los Servicios pueden ofrecerle una serie de métodos de pago que pueden ser utilizados, mostrando a continuación una lista (enunciativa mas no limitativa) de ellos: <br /><br />
    - Tarjeta de crédito y débito. <br />
    - Pago móvil (digital wallets, qr, etcétera). <br />
    - Facturación a través de la tienda de aplicaciones (Google Play Store y App Store). <br />
    - Pasarela de pagos (como PayPal, Mercado Pago, entre otras). <br />
    - Pago en efectivo (a través de una referencia de pago en las tiendas participantes). <br />
    - Depósitos. <br />
    - Transferencias bancarias. <br /> <br />
    Al momento de seleccionar el método de pago, el usuario puede seleccionar aquel que le genere mayor confianza; sin embargo, al momento de utilizar una forma de pago ajena a Los Servicios y/o perteneciente a un tercero, será redirigido a la página o aplicación de dicho intermediario para realizar el pago correspondiente. En estos casos, cualquier problema relacionado con la transacción y uso de los datos personales para la facturación, serán responsabilidad de dichos terceros sin involucrar a Los Servicios ni a La Empresa, quien en ningún caso será responsable. El incumplimiento de lo anterior será responsabilidad única y exclusiva del usuario, quien, en su caso, se obliga a indemnizar a La Empresa en caso de que ésta resulte demandada. <br /><br />
    Dentro del contenido de pago ofertado por Los Servicios también se pueden incluir microtransacciones para la compra de artículos virtuales o acceder a contenido adicional de forma individual; las cuales sólo representan la adquisición de una licencia personal, limitada, intransferible y revocable para utilizar dichas adquisiciones dentro de Los Servicios.`,
  },
  {
    title: "Reembolsos",
    content: `Todas aquellas compras realizadas con éxito son definitivas y no reembolsables, salvo que la legislación aplicable determine lo contrario. Únicamente se evaluarán las solicitudes de reembolso cuando éstas se realicen de manera particular y si se llegase a determinar que existió un error imputable al funcionamiento de Los Servicios.`,
  },
  {
    title: "Privacidad",
    content: `Para hacer uso de Los Servicios y sus funciones, es necesario recopilar, usar y compartir cierta información personal tanto de los usuarios, como de los visitantes; por lo tanto, de conformidad con lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, se informa a los usuarios el tipo de datos que se recaban de ellos, así como los fines o el tratamiento para los mismos. Los Servicios y/o La Empresa (cuya identidad y domicilio se señala al inicio de los presentes Términos y Condiciones) serán los responsables de recabar y tratar los datos.`,
  },
  {
    title: "Datos personales que se recaban de los usuarios",
    content: `La información obtenida de los usuarios se recaba de manera lícita, en estricto apego a las leyes y de la siguiente forma: <br /><br />
    - Datos proporcionados por el usuario. En esta categoría se incluyen todos aquellos datos que son proporcionados de manera voluntaria por los usuarios, por ejemplo, al momento de rellenar el formulario correspondiente para la creación de una cuenta, la información de facturación para la realización de pagos dentro de Los Servicios, la inscripción en sorteos o concursos, etcétera. De este método se pueden recabar los siguientes datos: nombre(s), apellidos, edad, fecha de nacimiento, correo electrónico, número de teléfono, dirección completa, información de facturación y número de vuelo. <br /><br />
    - Datos recabados de manera automática. Desde el momento en el que se accede a alguno de Los Servicios, se puede registrar y almacenar información automáticamente a través del sistema de Cookies u otras tecnologías. Dichos datos pueden ser controlados en todo momento por parte de los usuarios a través de las configuraciones de su navegador, sin embargo, pueden ser necesarias para el buen funcionamiento de las funciones de Los Servicios. De este método se pueden recabar los siguientes datos: la dirección de protocolo de internet (Dirección IP), el tipo de dispositivo utilizado para acceder a Los Servicios, el navegador en uso, la ubicación del dispositivo, métricas de uso e identificadores de publicidad. <br /><br />
    - Información de fuentes externas. A través de éstas se podrá obtener información adicional sobre los usuarios, siempre y cuando se cuente con el consentimiento de éstos; por ejemplo, a través de las redes sociales, al publicar o cargar contenido relacionado con Los Servicios. De este método se pueden recabar los siguientes datos: nombre de usuario en redes sociales, mensajes dirigidos directa o indirectamente hacia La Empresa o Los Servicios, comprobación de identidad, entre otros. <br /><br />
    A través del permiso otorgado por el usuario, se podrá acceder a diferentes funcionalidades del dispositivo como la cámara, el micrófono, el almacenamiento, portapapeles y el GPS (Sistema de Posicionamiento Global); mediante los cuales se podrán obtener datos como la ubicación precisa del dispositivo, grabaciones de audio y de video generadas por Los Servicios única y exclusivamente durante su uso, datos copiados desde otras aplicaciones, entre otros. <br /><br />
    Es preciso señalar que las acciones de entrega o recolección de todos estos datos personales en ningún momento tienen un carácter coercitivo, pues son los usuarios quienes deciden proporcionarlos o no. Sin embargo, la mayoría de ellos son de vital importancia para el funcionamiento de Los Servicios, por lo que sin ellos no se podrá acceder ni hacer uso algunas o todas las funciones de éstos.`,
  },
  {
    title: "Tratamiento de los datos",
    content: `Los datos obtenidos de los usuarios serán utilizados para los siguientes fines: <br /><br />
    - Crear una cuenta de usuario. <br />
    - Ejecutar las diferentes funciones de Los Servicios. <br />
    - Dar respuesta y seguimiento a solicitudes y preguntas. <br />
    - Monitorear el funcionamiento de Los Servicios. <br />
    - Crear promociones, sorteos y concursos. <br />
    - Mantener informados a los usuarios acerca de las novedades de Los Servicios. <br />
    - Personalizar y procesar pagos. <br />
    - Planear y ejecutar estrategias de marketing, así como medir su nivel de efectividad. <br />
    - Llevar a cabo análisis respecto al uso de Los Servicios. <br />
    - Desarrollar nuevas y mejores características de Los Servicios. <br />
    - Detectar posibles violaciones de los presentes Términos y Condiciones. <br />
    - Verificar la identidad de los usuarios.`,
  },
  {
    title: "Información que se comparte",
    content: `Al aceptar proporcionarnos sus datos y hacer uso de Los Servicios, el usuario manifiesta su aceptación para la divulgación de sus datos personales bajo los siguientes términos: <br /><br />
    - Es posible que los datos se compartan con diferentes proveedores de servicios y socios comerciales con el único fin de mantener un buen funcionamiento de Los Servicios, los cuales deberán asumir las mismas obligaciones de protección a la privacidad de los datos. <br />
    - Los datos de los usuarios no serán comercializados; únicamente serán compartidos con aquellas empresas que tengan alguna clase de relación con La Empresa. <br />
    - En cuanto a los medios de pago operados por terceros, podría transmitirse información relacionada con su identidad y su cuenta dentro de Los Servicios, con la principal finalidad de acreditar las compras realizadas. <br />
    - En cuanto al almacenamiento de datos, podrían transferirse todos los datos personales recabados de los usuarios con el único propósito de resguardarlos y optimizar su mantenimiento. <br />
    - Ciertos datos personales de contacto pueden ser compartidos con terceros especializados en la atención al cliente. <br />
    - Algunos datos de los usuarios, sin ser suficientes para identificar de manera individual a cada uno de los usuarios, pueden compartirse con terceros para la elaboración de análisis que permitan evaluar el desempeño de Los Servicios, así como posibles campañas publicitarias.`,
  },
  {
    title: "Medios para ejercer los derechos ARCO",
    content: `La Ley Federal de Protección de Datos Personales en Posesión de los Particulares le otorga los usuarios la posibilidad de ejercer sus derechos de Acceso, Rectificación, Cancelación y Oposición respecto a sus datos personales, para lo cual será necesario presentar a La Empresa una solicitud (por cualquiera de los medios de contacto contenidos en estos Términos y Condiciones) con los siguientes datos: <br /><br />
    I. El nombre del titular y domicilio u otro medio para comunicarle la respuesta a su solicitud; <br />
    II. Los documentos que acrediten la identidad o, en su caso, la representación legal del titular; <br />
    III. La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados (ARCO), así como las modificaciones a realizar en los mismos; y <br />
    IV. Cualquier otro elemento o documento que facilite la localización de los datos personales. <br /><br />
    En algunos casos es posible que se requiera más información de los usuarios para acceder a sus derechos ARCO; esto con la principal finalidad de garantizar que dicha petición provenga efectivamente del titular.`,
  },
  {
    title: "Actualización de privacidad",
    content: `La Empresa se reserva el derecho a realizar cualquier tipo de cambio a la presente política de privacidad (contenida y que forma parte integrante de los Términos y Condiciones) cuando, a entera discreción de La Empresa, sea necesario o conveniente. Será responsabilidad de los usuarios consultar dicha política periódicamente para conocer todos los cambios efectuados; en el entendido de que, al seguir utilizando Los Servicios, se entenderá como la aceptación de dicha política.`,
  },
  {
    title: "Legislación aplicable",
    content: `Para la interpretación y cumplimiento de los presentes Términos y Condiciones, así como para resolver todo lo que no se encuentre estipulado en los mismos, se aplicará lo dispuesto por las leyes mexicanas en la materia. Sin perjuicio de lo anterior, el usuario acuerda someterse a la jurisdicción territorial y competencia de los tribunales de la Ciudad de México o (a elección de la parte actora) del distrito judicial de Puebla para la resolución de cualquier conflicto, renunciando expresamente a cualquier otra jurisdicción y/o competencia territorial que pudiera corresponderle debido a su domicilio actual o futuro o por cualquier otro concepto. Si alguna disposición del presente fuese declarada nula por un tribunal que tenga la jurisdicción y competencia correspondiente, dicha invalidez no afectará el resto del contenido.`,
  },
];
