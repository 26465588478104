import React, { useState, useContext, useEffect } from "react";

import PasswordPopup from "./popUps/passwordPopup";
import ContinueButton from "./buttons/continueButton";
import { TimerContext } from "./timerContext_new";
import SuccessPopup from "./popUps/successPopup";

// Imágenes
import backgroundImage from "../imgs/people_background.webp";
import timeRedIcon from "../imgs/timer-red.svg";
import diploma from "../imgs/diplomas/Diplomas_paciente-0.webp";

// Iconos
import location from "../imgs/cuartos/Paciente0/location.png";

// Firebase
import { useAuth } from "./AuthProvider";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const FinalPaciente0 = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [error, setError] = useState("");
  const { timeLeft, stopTimer, restartTimer } = useContext(TimerContext);
  const { currentUser } = useAuth();

  // Efecto para recuperar el tiempo guardado
  useEffect(() => {
    const getStoredTime = async () => {
      if (currentUser) {
        const userDocRef = doc(db, "paciente_0", currentUser.uid);
        try {
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            if (userData.currentTime) {
              restartTimer(userData.currentTime);
            }
          }
        } catch (error) {
          console.error("Error al recuperar el tiempo:", error);
        }
      }
    };

    getStoredTime();
  }, [currentUser, restartTimer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const calculateTimeElapsed = () => {
    const totalTime = 30 * 60; // 30 minutos
    const timeElapsedInSeconds = totalTime - timeLeft;
    return formatTime(timeElapsedInSeconds);
  };

  const handlePasswordSubmit = async (password) => {
    if (password === "Guatemala") {
      try {
        const userDocRef = doc(db, "paciente_0", currentUser.uid);
        const timeElapsed = calculateTimeElapsed();

        await updateDoc(userDocRef, {
          currentPhase: 7,
          completionTime: timeElapsed,
        });

        stopTimer();
        setShowPopup(false);
        setShowSuccessPopup(true);
        setError("");
      } catch (error) {
        console.error("Error al actualizar en Firestore:", error);
        setError("Ocurrió un error. Intenta nuevamente.");
      }
    } else {
      setError("Contraseña incorrecta. Intenta de nuevo.");
    }
  };

  return (
    <div
      className="min-h-screen bg-blueCTCbg bg-cover bg-top bg-no-repeat flex flex-col items-center justify-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="absolute top-8 text-center">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-white text-5xl font-bold bg-redCTC px-8 py-2">
            Paciente 0
          </h1>
        </div>
        <div className="flex items-center justify-center mt-2">
          <img src={location} alt="Logo" className="w-6 h-6" />
          <p className="text-white md:text-lg text-sm font-roboto-mono">
            Aeropuerto de Guadalajara Terminal A
          </p>
        </div>
        <div className="flex items-center justify-center p-2 rounded-md shadow-md text-white font-roboto-mono">
          <img src={timeRedIcon} alt="Tiempo" className="h-6 w-6 mr-2" />
          <span className="text-lg">{formatTime(timeLeft)}</span>
        </div>
      </div>

      <ContinueButton setShowPopup={setShowPopup} bgColor="bg-greenPaciente0" />

      {showPopup && (
        <PasswordPopup
          onSubmit={handlePasswordSubmit}
          onClose={() => setShowPopup(false)}
          titleText="Ingresa la contraseña"
          buttonText="Verificar"
          error={error}
        />
      )}

      {showSuccessPopup && (
        <SuccessPopup
          onClose={() => setShowSuccessPopup(false)}
          timeElapsed={calculateTimeElapsed()}
          imageUrl={diploma}
          message={
            "¡Felicitaciones! Has completado el escape room del Paciente 0."
          }
          roomId="paciente_0"
        />
      )}
    </div>
  );
};

export default FinalPaciente0;
