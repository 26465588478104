import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "../firebase"; // Importar la base de datos Firestore
import { doc, getDoc } from "firebase/firestore"; // Función para obtener documentos

//Proveedor de autenticación para dar acceso a los datos del usuario
//Creado por: Alberto Cebreros

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // Si el usuario está autenticado, obtén los datos adicionales de Firestore
        const userDoc = await getDoc(doc(db, "users", user.uid));

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUser({
            ...user,
            firstName: userData.firstName || "Nombre no disponible",
            lastName: userData.lastName || "Apellido no disponible",
            birthDate: userData.dateOfBirth
              ? `${userData.dateOfBirth.day}/${userData.dateOfBirth.month}/${userData.dateOfBirth.year}`
              : "Fecha de nacimiento no disponible",
            gender: userData.gender || "Género no disponible",
            referralSource: userData.referralSource || "Fuente no disponible",
          });
        } else {
          setCurrentUser(user); // Si no hay datos adicionales, usa solo los datos de autenticación
        }
      } else {
        setCurrentUser(null); // Si no hay usuario autenticado, actualiza currentUser a null
      }
      setLoading(false); // Marca como cargado
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signOut: () => auth.signOut(),
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
