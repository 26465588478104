// src/components/Component.js
import React, { useState } from "react";

import Header from "./header";
import Footer from "./footer";

import { useAuth } from "../components/AuthProvider"; // Importa useAuth del AuthProvider
import SectionOne from "./sectionOne";
import Testimonials from "./testimonials";
import EscapeRooms from "./escapeRooms";
import SignIn from "./SignIn";

import "../index.css";
import WhatsAppButton from "./whatsAppButton";
import WelcomePopup from "./popUps/welcomePopup"; // Importa el WelcomePopup

export default function Component() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { currentUser } = useAuth(); // Usa el contexto de autenticación
  const [isWelcomeOpen, setIsWelcomeOpen] = useState(!currentUser); // Estado para el WelcomePopup
  const [isSignInOpen, setIsSignInOpen] = useState(false); // Estado para el SignIn

  return (
    <div className="flex flex-col min-h-[100dvh] w-full">
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <main
        className={`flex-1 ${
          menuOpen ? "landscape-mobile" : "mt-0"
        } landscape-desktop`}
      >
        <WhatsAppButton />
        <SectionOne />
        <div className="bg-black">
          <div id="escapeRooms">
            <EscapeRooms />
          </div>
          <Testimonials />
        </div>
      </main>
      <div id="footer">
        <Footer />
      </div>

      {/* Muestra el WelcomePopup si el usuario no está autenticado */}
      {isWelcomeOpen && (
        <WelcomePopup
          onClose={() => setIsWelcomeOpen(false)}
          openSignIn={() => setIsSignInOpen(true)}
        />
      )}

      {/* Muestra el SignIn popup si isSignInOpen es true */}
      {isSignInOpen && <SignIn onClose={() => setIsSignInOpen(false)} />}
    </div>
  );
}
