import React from "react";

//PopUp que se muestra cuando el usuario intenta entrar a un cuarto que ya ha sido completado
//Creado por: Alberto Cebreros

const AlreadyCompletedPopup = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="relative w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow text-center">
        <h2 className="text-2xl text-white font-nebulica font-bold mb-4">
          ¡Ya has completado este cuarto!
        </h2>
        <button
          onClick={onClose}
          className="bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default AlreadyCompletedPopup;
