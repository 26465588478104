import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//Icono de header
//Creado por: Alberto Cebreros

export default function HeaderIcon({
  children,
  text,
  targetId,
  route,
  ...props
}) {
  const [hovered, setHovered] = useState(false);
  const [width, setWidth] = useState(0);
  const ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (targetId && location.hash === `#${targetId}`) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location, targetId]);

  const handleClick = () => {
    if (route) {
      // Navegar a la ruta deseada y luego agregar hash para el scroll
      navigate(`${route}#${targetId}`);
    } else if (targetId) {
      // Solo hacer scroll si no se pasa una ruta
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <button
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
      className={`flex p-2 items-center rounded-lg text-white`}
      {...props}
    >
      {children}
      <div
        style={{ width: hovered ? ref.current?.offsetWidth || 0 : 0 }}
        className="sm:overflow-x-hidden sm:transition-all sm:duration-300 sm:ease-out whitespace-nowrap"
      >
        <span ref={ref} className="px-1.5">
          {text}
        </span>
      </div>
    </button>
  );
}
