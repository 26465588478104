import React, { useState } from "react";

//PopUp que se muestra en los cuartos para verificar la clave secreta
//Creado por: Alberto Cebreros

const PasswordPopup = ({
  onSubmit,
  onClose,
  titleText = "Ingresa la Clave Secreta",
  placeholderText = "Clave secreta",
  buttonText = "Verificar Clave",
  error,
}) => {
  const [clave, setClave] = useState("");

  const handleSubmit = () => {
    onSubmit(clave);
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
      <div className="relative w-96 mx-auto p-6 bg-blueCTC rounded-lg shadow-lg contact-form-glow">
        <button
          className="absolute top-2 right-4 text-white text-2xl"
          onClick={onClose}
        >
          &times;
        </button>

        <h2 className="text-2xl text-white font-nebulica font-bold mb-4">
          {titleText}
        </h2>
        <input
          type="text"
          value={clave}
          onChange={(e) => setClave(e.target.value)}
          placeholder={placeholderText}
          className="w-full px-4 py-2 text-black rounded-md"
        />
        {error && <p className="text-red-500 mt-2">{error}</p>}

        <div className="mt-6">
          <button
            className="bg-redCTC text-white px-4 py-2 rounded-md hover:bg-primary/80 flex items-center justify-center"
            onClick={handleSubmit}
          >
            {/* <img src={confirmIcon} alt="Confirmar" className="w-6 h-6 inline" /> */}
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordPopup;
